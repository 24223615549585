<template>
  <Dialog
    :header="
      companyTypeIndex == null
        ? $t('settings.createCompanyType')
        : $t('settings.editCompanyType')
    "
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '40vw' }"
  >
    <div class="grid grid-nogutter vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0">
        <label for="code">{{ $t("settings.code") }}*:</label>
      </div>
      <div class="field col-12 md:col-6 pt-0 md:pt-3">
        <InputText
          id="code"
          :class="{
            'p-invalid': v$.companyType.code.$invalid && submitted,
          }"
          aria-describedby="code-error"
          v-model="v$.companyType.code.$model"
          :placeholder="$t('settings.code') + ' *'"
          :showIcon="true"
        />
      </div>
      <ul
        v-if="v$.companyType.code.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-3 mt-0"
      >
        <li v-for="(error, index) of v$.companyType.code.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("settings.code")) }}
        </li>
      </ul>
    </div>

    <div class="grid grid-nogutter vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0">
        <label for="branch">{{ $t("settings.branch") }}*:</label>
      </div>
      <div class="field col-12 md:col-6 pt-0 md:pt-3">
        <InputText
          id="branch"
          :class="{
            'p-invalid': v$.companyType.branch.$invalid && submitted,
          }"
          aria-describedby="branch-error"
          v-model="v$.companyType.branch.$model"
          :placeholder="$t('settings.branch') + ' *'"
          :showIcon="true"
        />
      </div>
      <ul
        v-if="v$.companyType.branch.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-3 mt-0"
      >
        <li
          v-for="(error, index) of v$.companyType.branch.$errors"
          :key="index"
        >
          {{ error.$message.replace("[value]", $t("settings.branch")) }}
        </li>
      </ul>
    </div>

    <div class="grid">
      <div class="col">
        <span>* {{ $t("global.mandatoryFields") }}</span>
      </div>
    </div>

    <template #footer>
      <Button
        :label="
          companyTypeIndex == null ? $t('global.create') : $t('global.save')
        "
        class="p-button-success"
        @click="create"
        autofocus
        :loading="loadingButton"
        iconPos="right"
      />
      <Button
        :label="$t('global.close')"
        @click="close()"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
import { mapGetters } from "vuex";
export default {
  name: "Add edit company type",
  props: ["display", "companyTypeIndex"],
  emits: ["closeAddEditCompanyType"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      companyType: {
        code: "",
        branch: "",
      },
      submitted: false,
      loadingButton: false,
    };
  },
  computed: {
    ...mapGetters(["companyTypes"]),
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      if (this.companyTypeIndex != null) {
        this.companyType = JSON.parse(
          JSON.stringify(this.companyTypes[this.companyTypeIndex])
        );
      }
    },
    viewDialog(val) {
      if (val == false) {
        this.close();
        this.submitted = false;
        this.v$.$reset();
      } else {
        this.submitted = false;
      }
    },
  },
  validations: {
    companyType: {
      code: {
        required,
      },
      branch: {
        required,
      },
    },
  },
  methods: {
    clearCompanyType() {
      this.companyType = {
        code: "",
        branch: "",
      };
    },
    async create() {
      this.submitted = true;
      const result = await this.v$.$validate();
      if (result) {
        const tempCompanyTypes = [...this.companyTypes];
        if (this.companyTypeIndex != null) {
          tempCompanyTypes[this.companyTypeIndex] = this.companyType;
          this.$store.commit("companyTypes", tempCompanyTypes);
          this.close();
        } else {
          this.close(this.companyType);
        }
      }
    },
    close(newCompanyType = null) {
      this.$emit("closeAddEditCompanyType", newCompanyType);
      this.clearCompanyType();
    },
  },
};
</script>
