<template>
  <AddEditCompanyType
    :display="showAddEditCompanyType"
    :companyTypeIndex="editCompanyTypeIndex"
    @closeAddEditCompanyType="closeAddEditCompanyType"
  ></AddEditCompanyType>
  <BulkImport
    :display="showBulkImport"
    :type="'companyType'"
    @closeImport="closeBulkImport"
    @importData="importData"
  ></BulkImport>
  <Card>
    <template #title>
      <div class="grid">
        <div class="col-6 md:col-2">
          <h4>{{ $t("settings.companyType") }}</h4>
        </div>
        <div class="text-center text-right col-6 md:col-2">
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-left md:text-right">
          <Button
            :label="$t('settings.bulkDownload')"
            @click="bulkExport"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button
            :label="$t('settings.bulkAddition')"
            @click="bulkCompanyType"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-left md:text-right">
          <Button
            :label="$t('settings.addCompanyType')"
            @click="addCompanyType"
            class="mr-3"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button :label="$t('global.saveJson')" @click="exportJson"></Button>
        </div>
      </div>
    </template>
    <template #content>
      <DataTable
        :value="companyTypeValues"
        @rowReorder="onRowReorder"
        responsiveLayout="scroll"
        showGridlines
        :loading="loading"
        ref="companyTypesTable"
        @page="changePage"
      >
        <Column
          :rowReorder="true"
          :reorderableColumn="false"
          headerStyle="width: 3rem"
        />
        <Column field="code" :header="$t('settings.code')"> </Column>
        <Column field="branch" :header="$t('settings.branch')"></Column>
        <Column :header="$t('global.actions')">
          <template #body="{ data, index }">
            <div class="flex justify-content-evenly align-items-center">
              <Button
                class="p-button-text mdi mdi-24px mdi-pencil"
                @click="editCompanyType(index)"
              />
              <Button
                icon="mdi mdi-close-circle mdi-24px"
                class="p-button-rounded p-button-text p-button-text"
                @click="deleteCompanytype(data, index)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
import AddEditCompanyType from "./AddEditCompanyType.vue";
import BulkImport from "../BulkImport.vue";
export default {
  name: "CompanyType",
  components: {
    AddEditCompanyType,
    BulkImport,
  },
  data() {
    return {
      companyTypeValues: [],
      showAddEditCompanyType: false,
      editCompanyTypeIndex: null,
      showBulkImport: false,
      loading: false,
      currentPage: 0,
      currentRows: null,
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("companyTypes");
    this.loading = false;
  },
  computed: {
    ...mapGetters(["companyTypes", "isMobile"]),
  },
  watch: {
    companyTypes() {
      this.companyTypeValues = [...this.companyTypes];
    },
  },
  methods: {
    changePage(data) {
      this.currentPage = data.page;
      this.currentRows = data.rows;
    },
    addCompanyType() {
      this.showAddEditCompanyType = true;
    },
    closeAddEditCompanyType(newCompanyType) {
      this.showAddEditCompanyType = false;
      this.editCompanyTypeIndex = null;

      if (newCompanyType) {
        this.companyTypeValues.push(newCompanyType);
        this.$store.commit("companyTypes", this.companyTypeValues);
      }else {
        this.companyTypeValues = this.companyTypes;
      }
    },
    bulkCompanyType() {
      this.showBulkImport = true;
    },
    closeBulkImport() {
      this.showBulkImport = false;
    },
    onRowReorder(event) {
      console.log(event);
      this.companyTypeValues = [...event.value];
    },
    importData(data) {
      console.log(data);
      this.companyTypeValues = this.companyTypeValues.concat(data);
    },
    async exportJson() {
      this.$store.dispatch("exportJson", {
        name: "kad",
        json: this.companyTypeValues,
      });
    },
    editCompanyType(index) {
      this.showAddEditCompanyType = true;
      this.editCompanyTypeIndex = index + this.currentPage * this.currentRows;
    },
    deleteCompanytype(companyType, companyTypeIndex) {
      this.$confirm.require({
        message: this.$t("settings.deleteCompanyType", [companyType.branch]),
        header: this.$t("settings.deleteConfirmation"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: this.$t("global.yes"),
        rejectLabel: this.$t("global.no"),
        accept: () => {
          this.companyTypeValues.splice(
            companyTypeIndex + this.currentPage * this.currentRows,
            1
          );
          this.$store.commit("companyTypes", this.companyTypeValues);
        },
      });
    },
    exportCSV() {
      this.$refs.companyTypesTable.exportCSV();
    },
    async bulkExport() {
      await this.$store.dispatch("bulkExport", "kad");
    },
  },
};
</script>
